<template>
  <div class="box">
    <h2>城固县城区占道停车<br/>收费员上岗证</h2>
    <img :src="userInfo.headImg" alt="加载中">
    <p><span>姓名</span><span>：</span><span>{{ userInfo.nickName }}</span></p>
    <p><span>编号</span><span>：</span><span>{{ userInfo.cardNumber }}</span></p>
    <p><span>单位</span><span>：</span><span>{{ companyName }}</span></p>
    <p><span>监督电话</span><span>：</span><span>{{ mobile }}</span></p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userInfo: {
        headImg: "",
        cardNumber: "--",
        nickName: "--",
      },
      companyName: process.env.VUE_APP_COMPANY_NAME,
      mobile: "",
    };
  },
  created() {
    const id = this.$route.query.id;
    if (id) {
      this.getUserInfo(id);
      this.$http.get(
        "/park-service-system/config/selectValueByKey",
        { key: "connect_tell" },
        (res) => {
          this.mobile = res.configValue;
        },
        false
      );
    } else {
      this.$toast.fail("未获取到工作人员信息，请核对二维码");
    }
  },
  methods: {
    getUserInfo(id) {
      this.$http.get(
        "/park-service-park/staff/selectParkUserDetail",
        { id },
        (res) => {
          this.userInfo = res;
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  height: 100%;
  background-image: url("~@/assets/cardbg.jpg");
  background-size: 66px 100%;
  font-size: 0.32rem;
  text-align: center;
  h2 {
    text-align: center;
    padding: 50px 0 30px 0;
  }
  img {
    width: 130px;
    height: auto;
    margin-bottom: 20px;
  }
  p {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 6rem;
    padding: 10px 0;
    margin: 0 auto;
    span {
      &:first-child {
        display: block;
        width: 1.6rem;
        text-align-last: justify;
        text-align: justify;
      }
      &:last-child {
        flex: 1;
        border-bottom: 1px solid #666;
        text-align: center;
        padding: 3px 0;
      }
    }
  }
}
</style>
